import React from 'react';
import MessageCard from './MessageCard';
import { Typography } from '@mui/material';

export default function GroupChatList() {
    const groupChats = [
        {
            avatar: 'https://via.placeholder.com/40',
            title: '前端开发交流群',
            time: '昨天 20:30',
            content: '大家晚上好，有没有前端面试的经验可以分享？',
        },
        {
            avatar: 'https://via.placeholder.com/40',
            title: '设计爱好者群',
            time: '今天 11:00',
            content: '有新UI设计素材分享，快来看看！',
        },
    ];

    return (
        <>
            <Typography variant="h6" gutterBottom>
                
            </Typography>
            {groupChats.map((chat, index) => (
                <MessageCard
                    key={index}
                    avatar={chat.avatar}
                    title={chat.title}
                    time={chat.time}
                    content={chat.content}
                    onClick={() => console.log('跳转到群聊页面')}
                />
            ))}
        </>
    );
}