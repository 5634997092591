import React from 'react';
import MessageCard from './MessageCard';
import { Typography } from '@mui/material';

export default function SystemNotificationList() {
    const systemNotifications = [
        {
            avatar: 'https://via.placeholder.com/40',
            title: '系统通知',
            time: '今天 10:15',
            content: '您的认证资料已通过审核。',
        },
        {
            avatar: 'https://via.placeholder.com/40',
            title: '系统通知',
            time: '昨天 19:50',
            content: '您发布的职位信息已成功上线。',
        },
    ];

    return (
        <>
            <Typography variant="h6" gutterBottom>
            </Typography>
            {systemNotifications.map((notification, index) => (
                <MessageCard
                    key={index}
                    avatar={notification.avatar}
                    title={notification.title}
                    time={notification.time}
                    content={notification.content}
                    onClick={() => console.log('跳转到系统消息详情页面')}
                />
            ))}
        </>
    );
}