import React from "react";
import { Box, Typography, Button } from "@mui/material";

const PostPage = () => {
    return (
        <Box sx={{ padding: 3, textAlign: "center" }}>
            <Typography variant="h4" gutterBottom>
                发布职位或动态
            </Typography>
            <Typography variant="body1">在这里可以发布职位或动态。</Typography>
            <Button variant="contained" color="primary" sx={{ marginTop: 2 }}>
                新增发布
            </Button>
        </Box>
    );
};

export default PostPage;