import React from 'react';
import MessageCard from './MessageCard';
import { Typography } from '@mui/material';

export default function RecruitmentChatList() {
    const recruitmentChats = [
        {
            avatar: 'https://via.placeholder.com/40',
            title: '求职者 - 张三',
            time: '今天 14:00',
            content: '您好，我对您发布的职位很感兴趣，可以聊聊吗？',
        },
        {
            avatar: 'https://via.placeholder.com/40',
            title: '求职者 - 李四',
            time: '昨天 21:30',
            content: '请问该职位是否支持远程办公？',
        },
    ];

    return (
        <>
            <Typography variant="h6" gutterBottom>
            </Typography>
            {recruitmentChats.map((chat, index) => (
                <MessageCard
                    key={index}
                    avatar={chat.avatar}
                    title={chat.title}
                    time={chat.time}
                    content={chat.content}
                    onClick={() => console.log('跳转到招聘聊天页面')}
                />
            ))}
        </>
    );
}