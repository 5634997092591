import React, { useState } from 'react'; // 添加 useState 的导入
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Box, Typography, Snackbar, Alert } from '@mui/material';

export default function LoginPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const navigate = useNavigate();

  const handleLogin = () => {
    // 模拟登录成功逻辑
    const isLoginSuccessful = true; // 假设登录成功
    if (isLoginSuccessful) {
      const isFirstLogin = true; // 假设这是第一次登录
      if (isFirstLogin) {
        navigate('/interest-and-profile'); // 跳转到兴趣标签选择页面
      } else {
        navigate('/dashboard'); // 跳转到用户主页或其他页面
      }
    } else {
      setSnackbarOpen(true); // 显示错误提示
    }
  };

  return (
    <Box sx={{ width: '100%', maxWidth: 400, margin: 'auto', mt: 8, textAlign: 'center' }}>
      <Typography variant="h4" gutterBottom>登录</Typography>
      <TextField
        label="邮箱"
        type="email"
        fullWidth
        margin="normal"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <TextField
        label="密码"
        type="password"
        fullWidth
        margin="normal"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <Button variant="contained" color="primary" fullWidth sx={{ mt: 2 }} onClick={handleLogin}>
        登录
      </Button>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert severity="error" onClose={() => setSnackbarOpen(false)}>
          登录失败，请检查您的邮箱和密码。
        </Alert>
      </Snackbar>
    </Box>
  );
}