import React from 'react';
import { Box, Chip } from '@mui/material';

export default function TagList() {
    const tags = ['前端开发', 'Vue.js', 'Node.js', 'Web3'];
    return (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, marginBottom: 2 }}>
            {tags.map((tag, index) => (
                <Chip key={index} label={tag} color="primary" />
            ))}
        </Box>
    );
}