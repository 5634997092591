import React from 'react';
import { Box, Typography, List, ListItem, ListItemText } from '@mui/material';

export default function SectionList({ title, links }) {
    return (
        <Box sx={{ marginBottom: 3 }}>
            <Typography variant="h6">{title}</Typography>
            <List>
                {links.map((link, index) => (
                    <ListItem key={index} button component="a" href={link.href}>
                        <ListItemText primary={link.text} />
                    </ListItem>
                ))}
            </List>
        </Box>
    );
}