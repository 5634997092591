import React, { useState } from 'react';
import { Box, Tabs, Tab, Typography } from '@mui/material';
import PersonalChatList from './PersonalChatList';
import GroupChatList from './GroupChatList';
import JobChatList from './JobChatList';
import RecruitmentChatList from './RecruitmentChatList';
import SystemNotificationList from './SystemNotificationList';

export default function MessagesPage() {
    const [selectedTab, setSelectedTab] = useState(0);

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    return (
        <Box sx={{ width: '100%', padding: 2 }}>
            {/* 顶部选项卡 */}
            <Tabs
                value={selectedTab}
                onChange={handleTabChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                sx={{ marginBottom: 2 }}
            >
                <Tab label="个人聊天" />
                <Tab label="群聊" />
                <Tab label="求职会话" />
                <Tab label="招聘会话" />
                <Tab label="系统消息" />
            </Tabs>

            {/* 对应的消息内容 */}
            <Box sx={{ marginTop: 2 }}>
                {selectedTab === 0 && (
                    <Box>
                        <Typography variant="h6" gutterBottom>
                            个人聊天
                        </Typography>
                        <PersonalChatList />
                    </Box>
                )}
                {selectedTab === 1 && (
                    <Box>
                        <Typography variant="h6" gutterBottom>
                            群聊
                        </Typography>
                        <GroupChatList />
                    </Box>
                )}
                {selectedTab === 2 && (
                    <Box>
                        <Typography variant="h6" gutterBottom>
                            求职会话
                        </Typography>
                        <JobChatList />
                    </Box>
                )}
                {selectedTab === 3 && (
                    <Box>
                        <Typography variant="h6" gutterBottom>
                            招聘会话
                        </Typography>
                        <RecruitmentChatList />
                    </Box>
                )}
                {selectedTab === 4 && (
                    <Box>
                        <Typography variant="h6" gutterBottom>
                            系统消息
                        </Typography>
                        <SystemNotificationList />
                    </Box>
                )}
            </Box>
        </Box>
    );
}