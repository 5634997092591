import React from 'react';
import { Box, Typography } from '@mui/material';

export default function Stats() {
    return (
        <Box sx={{ marginBottom: 3 }}>
            <Typography>加入时间：2022年5月15日</Typography>
            <Typography>发布资讯数量：15条</Typography>
            <Typography>近期获赞数量：30个</Typography>
            <Typography>近期获关注数量：10个</Typography>
            <Typography>了解了5个岗位</Typography>
            <Typography>被3个招聘方查看</Typography>
            <Typography>工作历程：参与了10个项目</Typography>
        </Box>
    );
}