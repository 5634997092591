import React from 'react';
import MessageCard from './MessageCard';
import { Typography } from '@mui/material';

export default function PersonalChatList() {
    const personalChats = [
        {
            avatar: 'https://via.placeholder.com/40',
            title: '张三',
            time: '昨天 18:42',
            content: '你好，请问你对这个职位感兴趣吗？',
        },
        {
            avatar: 'https://via.placeholder.com/40',
            title: '李四',
            time: '今天 09:15',
            content: '收到你的消息了，我们保持联系。',
        },
    ];

    return (
        <>
            <Typography variant="h6" gutterBottom>
            </Typography>
            {personalChats.map((chat, index) => (
                <MessageCard
                    key={index}
                    avatar={chat.avatar}
                    title={chat.title}
                    time={chat.time}
                    content={chat.content}
                    onClick={() => console.log('跳转到个人聊天页面')}
                />
            ))}
        </>
    );
}