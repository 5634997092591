import React, { useState } from 'react';
import { Box, Grid, Typography, Avatar, Button, Card, CardContent, Divider, TextField } from '@mui/material';
import TagList from '../components/TagList';
import Stats from '../components/Stats';
import SectionList from '../components/SectionList';
// import Footer from './Footer';

export default function ProfilePage() {
    const [companyInfo, setCompanyInfo] = useState('暂无企业信息'); // 企业信息
    const [editingCompanyInfo, setEditingCompanyInfo] = useState(false); // 企业信息编辑状态
    const [companyCertification, setCompanyCertification] = useState(false); // 企业认证状态

    const handleSaveCompanyInfo = () => {
        setEditingCompanyInfo(false);
    };

    return (
        <Box>
            {/* 顶部背景区域 */}
            <Box
                sx={{
                    backgroundColor: '#1976d2',
                    color: 'white',
                    padding: 4,
                    textAlign: 'center',
                    position: 'relative',
                    borderRadius: '0 0 16px 16px',
                }}
            >
                <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                    我的个人资料
                </Typography>
                <Typography variant="body1" sx={{ opacity: 0.8, marginTop: 1 }}>
                    查看和管理您的个人信息
                </Typography>
            </Box>

            {/* 主体内容 */}
            <Box sx={{ padding: 3 }}>
                <Grid container spacing={3}>
                    {/* 左侧：个人信息卡片 */}
                    <Grid item xs={12} md={4}>
                        <Card
                            sx={{
                                textAlign: 'center',
                                boxShadow: 2,
                                borderRadius: '8px',
                                padding: 3,
                            }}
                        >
                            <Avatar
                                src="https://via.placeholder.com/100"
                                sx={{
                                    width: 120,
                                    height: 120,
                                    margin: '0 auto',
                                    marginBottom: 2,
                                }}
                            />
                            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                张三
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                前端开发工程师
                            </Typography>
                            <Typography variant="body2" color="text.secondary" sx={{ marginBottom: 2 }}>
                                北京 · 中国
                            </Typography>

                            {/* 企业信息管理 */}
                            <Divider sx={{ margin: '16px 0' }} />
                            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: 2 }}>
                                企业信息
                            </Typography>
                            {editingCompanyInfo ? (
                                <Box>
                                    <TextField
                                        fullWidth
                                        multiline
                                        rows={3}
                                        defaultValue={companyInfo}
                                        onChange={(e) => setCompanyInfo(e.target.value)}
                                        sx={{ marginBottom: 2 }}
                                    />
                                    <Button variant="contained" color="primary" onClick={handleSaveCompanyInfo}>
                                        保存
                                    </Button>
                                </Box>
                            ) : (
                                <Box>
                                    <Typography variant="body2" color="text.secondary">
                                        {companyInfo}
                                    </Typography>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        sx={{ marginTop: 2 }}
                                        href="/edit-company-info"
                                    >

                                        {/* onClick={() => setEditingCompanyInfo(true)} */}
                                        编辑企业信息
                                    </Button>
                                </Box>
                            )}

                            {/* 企业认证 */}
                            <Divider sx={{ margin: '16px 0' }} />
                            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: 2 }}>
                                企业认证
                            </Typography>
                            {companyCertification ? (
                                <Typography variant="body2" color="green">
                                    已认证
                                </Typography>
                            ) : (
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    fullWidth
                                    sx={{ marginTop: 2 }}
                                    href="/enterprise-certification"
                                >
                                    申请企业认证
                                </Button>
                            )}

                            <Divider sx={{ margin: '16px 0' }} />
                            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: 2 }}>
                                未登录
                            </Typography>
                            <Button
                                variant="contained"
                                color="blue"
                                fullWidth
                                sx={{ marginTop: 2 }}
                                href="/login"
                            >
                                登录
                            </Button>
                        </Card>

                        {/* 我的标签 */}
                        <Card
                            sx={{
                                marginTop: 3,
                                boxShadow: 2,
                                borderRadius: '8px',
                                padding: 2,
                            }}
                        >
                            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: 2 }}>
                                我的标签
                            </Typography>
                            <TagList />
                        </Card>
                    </Grid>

                    {/* 右侧：内容区域 */}
                    <Grid item xs={12} md={8}>
                        {/* 数据统计 */}
                        <Card
                            sx={{
                                boxShadow: 2,
                                borderRadius: '8px',
                                padding: 3,
                                marginBottom: 3,
                            }}
                        >
                            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: 2 }}>
                                数据统计
                            </Typography>
                            <Divider sx={{ marginBottom: 2 }} />
                            <Stats />
                        </Card>

                        {/* 其他信息 */}
                        <SectionList
                            title="我的发布"
                            links={[
                                { text: '草稿箱', href: '/drafts' },
                                { text: '发布列表', href: '/published-posts' },
                            ]}
                        />
                        <SectionList
                            title="我喜欢的"
                            links={[
                                { text: '喜欢的内容1', href: '#' },
                                { text: '喜欢的内容2', href: '#' },
                            ]}
                        />
                        <SectionList
                            title="我收藏的"
                            links={[
                                { text: '收藏的内容1', href: '#' },
                                { text: '收藏的内容2', href: '#' },
                            ]}
                        />
                        <SectionList
                            title="我评论的"
                            links={[
                                { text: '评论的内容1', href: '#' },
                                { text: '评论的内容2', href: '#' },
                            ]}
                        />
                        <SectionList
                            title="我关注的人"
                            links={[
                                { text: '关注的人1', href: '#' },
                                { text: '关注的人2', href: '#' },
                            ]}
                        />
                    </Grid>
                </Grid>
            </Box>

            {/* 底部菜单 */}
            {/* <Footer /> */}
        </Box>
    );
}