import React, { useState } from 'react';
import {
    Box,
    Typography,
    Chip,
    Button,
    Container,
    Grid,
    Snackbar,
    Alert,
    Divider,
} from '@mui/material';
import { Link } from 'react-router-dom';

const interestTags = [
    '前端开发',
    '后端开发',
    '人工智能',
    '数据分析',
    'UI/UX设计',
    '网络安全',
    '项目管理',
    '移动开发',
    '云计算',
    '电子商务',
];

export default function InterestAndProfilePage({ userType }) {
    const [selectedTags, setSelectedTags] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const handleTagToggle = (tag) => {
        if (selectedTags.includes(tag)) {
            setSelectedTags(selectedTags.filter((t) => t !== tag));
        } else {
            setSelectedTags([...selectedTags, tag]);
        }
    };

    const handleSubmitTags = () => {
        if (selectedTags.length === 0) {
            setSnackbarOpen(true);
            return;
        }
        console.log('Selected Tags:', selectedTags);
    };

    return (
        <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
            {/* 欢迎信息与兴趣标签 */}
            <Typography variant="h4" align="center" gutterBottom>
                欢迎来到 NorthTown
            </Typography>
            <Typography variant="body1" color="text.secondary" align="center" gutterBottom>
                选择您的兴趣标签，帮助我们为您推荐更相关的内容
            </Typography>
            <Box sx={{ mt: 3 }}>
                <Grid container spacing={2} justifyContent="center">
                    {interestTags.map((tag) => (
                        <Grid item key={tag}>
                            <Chip
                                label={tag}
                                onClick={() => handleTagToggle(tag)}
                                color={selectedTags.includes(tag) ? 'primary' : 'default'}
                                clickable
                            />
                        </Grid>
                    ))}
                </Grid>
            </Box>
            <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmitTags}
                    disabled={selectedTags.length === 0}
                >
                    确认选择兴趣标签
                </Button>
            </Box>

            <Divider sx={{ mt: 6, mb: 4 }} />

            {/* 完善信息 */}
            <Typography variant="h4" align="center" gutterBottom>
                完善您的信息
            </Typography>
            <Typography variant="body1" color="text.secondary" align="center" gutterBottom>
                提升您的个人或企业形象，让更多人了解您
            </Typography>
            <Grid container spacing={3} sx={{ mt: 3 }}>
                {userType === 'personal' && (
                    <Grid item xs={12}>
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            component={Link}
                            to="/edit-profile"
                        >
                            去完善个人简历
                        </Button>
                    </Grid>
                )}

                <>
                    <Grid item xs={12}>
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            component={Link}
                            to="/edit-company-info"
                        >
                            去完善企业信息
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            fullWidth
                            variant="contained"
                            color="secondary"
                            component={Link}
                            to="/enterprise-certification"
                        >
                            进行企业认证
                        </Button>
                    </Grid>
                </>

                <Grid item xs={12}>
                    <Button fullWidth variant="outlined" color="default"
                        component={Link}
                        to="/">
                        稍后再完善
                    </Button>
                </Grid>
            </Grid>

            {/* 提示信息 */}
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={() => setSnackbarOpen(false)}
            >
                <Alert
                    onClose={() => setSnackbarOpen(false)}
                    severity="warning"
                    sx={{ width: '100%' }}
                >
                    请至少选择一个标签
                </Alert>
            </Snackbar>
        </Container>
    );
}