import React from 'react';
import { Card, CardContent, Typography, Avatar, Grid, Box } from '@mui/material';

export default function MessageCard({ avatar, title, time, content, onClick }) {
    return (
        <Card sx={{ marginBottom: 2, cursor: 'pointer' }} onClick={onClick}>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item>
                        <Avatar src={avatar} />
                    </Grid>
                    <Grid item xs>
                        <Typography variant="h6">{title}</Typography>
                        <Typography variant="body2" color="text.secondary">
                            {time}
                        </Typography>
                        <Typography variant="body1">{content}</Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}