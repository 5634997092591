import React, { useState } from 'react';
import {
  Box,
  Tabs,
  Tab,
  Typography,
  Grid,
  Card,
  CardContent,
  Avatar,
  Button,
  TextField,
} from '@mui/material';

function TabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

export default function JobsPage() {
  const [tabIndex, setTabIndex] = useState(0);
  const [subTabIndex, setSubTabIndex] = useState(0); // 新增：子选项卡的状态

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleSubTabChange = (event, newValue) => {
    setSubTabIndex(newValue);
  };

  const jobData = [
    {
      title: '软件工程师',
      location: '北京 | 全职 | 3年经验',
      salary: '20K-30K',
      benefits: '我们福利特别好',
      company: {
        name: '北极星科技有限公司',
        size: '500-999人',
        logo: 'https://via.placeholder.com/50',
      },
    },
    {
      title: '前端开发工程师',
      location: '上海 | 全职 | 2年经验',
      salary: '15K-25K',
      benefits: '技术强大，团队协作好',
      company: {
        name: '未来科技有限公司',
        size: '1000-9999人',
        logo: 'https://via.placeholder.com/50',
      },
    },
  ];

  const jobList = [
    {
      id: 1,
      title: '软件工程师',
      location: '北京 | 全职 | 3年经验',
      salary: '20K-30K',
      applicants: 10,
    },
    {
      id: 2,
      title: '前端开发工程师',
      location: '上海 | 全职 | 2年经验',
      salary: '15K-25K',
      applicants: 5,
    },
  ];

  const applications = [
    {
      id: 1,
      name: '李四',
      position: '前端开发工程师',
      resume: '简历链接1',
    },
    {
      id: 2,
      name: '张三',
      position: '软件工程师',
      resume: '简历链接2',
    },
  ];

  return (
    <Box sx={{ width: '100%', p: 2 }}>
      <Tabs
        value={tabIndex}
        onChange={handleTabChange}
        variant="fullWidth"
        centered
        textColor="primary"
        indicatorColor="primary"
      >
        <Tab label="推荐职位" />
        <Tab label="我的招聘" />
        <Tab label="推荐求职者" />
        <Tab label="我的简历" />
      </Tabs>

      <TabPanel value={tabIndex} index={0}>
        <TextField
          variant="outlined"
          fullWidth
          placeholder="搜索职位、地区、技能"
          sx={{ mb: 2 }}
        />
        <Grid container spacing={2}>
          {jobData.map((job, index) => (
            <Grid item xs={12} key={index}>
              <Card>
                <CardContent>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item>
                      <Avatar
                        src={job.company.logo}
                        alt={`${job.company.name} Logo`}
                        sx={{ width: 60, height: 60 }}
                      />
                    </Grid>
                    <Grid item xs>
                      <Typography variant="h6">{job.title}</Typography>
                      <Typography variant="body2" color="text.secondary">
                        {job.location}
                      </Typography>
                      <Typography variant="body2" sx={{ mt: 1 }}>
                        {job.benefits} | 薪资：{job.salary}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Box sx={{ textAlign: 'right' }}>
                        <Typography variant="body2">{job.company.name}</Typography>
                        <Typography variant="caption" color="text.secondary">
                          规模：{job.company.size}
                        </Typography>
                        <Button variant="outlined" size="small" sx={{ mt: 1 }}>
                          查看详情
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </TabPanel>

      <TabPanel value={tabIndex} index={1}>
        <Button variant="contained" fullWidth sx={{ mb: 2 }}>
          创建职位
        </Button>
        <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
          快速管理您的职位信息
        </Typography>

        <Tabs
          value={subTabIndex}
          onChange={handleSubTabChange}
          variant="fullWidth"
          textColor="primary"
          indicatorColor="primary"
          sx={{ mb: 2 }}
        >
          <Tab label="岗位列表" />
          <Tab label="所有投递" />
        </Tabs>

        <TabPanel value={subTabIndex} index={0}>
          <Grid container spacing={2}>
            {jobList.map((job) => (
              <Grid item xs={12} key={job.id}>
                <Card>
                  <CardContent>
                    <Typography variant="h6">{job.title}</Typography>
                    <Typography variant="body2" color="text.secondary">
                      {job.location} | 薪资：{job.salary}
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                      <Button variant="outlined" size="small" color="primary">
                        编辑
                      </Button>
                      <Button variant="outlined" size="small" color="error">
                        删除
                      </Button>
                      <Button variant="outlined" size="small" color="success">
                        查看简历（{job.applicants}）
                      </Button>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </TabPanel>

        <TabPanel value={subTabIndex} index={1}>
          <Grid container spacing={2}>
            {applications.map((app) => (
              <Grid item xs={12} key={app.id}>
                <Card>
                  <CardContent>
                    <Typography variant="h6">{app.name}</Typography>
                    <Typography variant="body2" color="text.secondary">
                      投递职位：{app.position}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {app.resume}
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                      <Button variant="outlined" size="small" color="success" sx={{ mr: 2 }}>
                        查看简历
                      </Button>
                      <Button variant="outlined" size="small" color="primary">
                        发起沟通
                      </Button>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </TabPanel>
      </TabPanel>
    </Box>
  );
}