import React from 'react';
import { Container, Grid, Typography, Box, Avatar, Card, CardContent, CardMedia, Button } from '@mui/material';
import {
    CardHeader,
    CardActions,
    IconButton,
} from "@mui/material";
import {
    ThumbUp,
    Comment,
    Share,
    MoreVert,
    PersonAdd,
} from "@mui/icons-material";



const hotTags = ['#程序员', '#招聘', '#前端开发', '#JavaScript'];
const categories = ['分类1', '分类2', '分类3', '分类4'];
const feeds = [
    {
        username: '张三',
        tags: ['#技术', '#前端开发'],
        content: '这是一个技术相关的资讯内容，前端开发技巧和分享。',
        image: 'https://via.placeholder.com/600x300',
    },
    {
        username: '李四',
        tags: ['#生活', '#随笔'],
        content: '分享一下我的日常生活和随笔，记录生活中的点滴。',
        image: 'https://via.placeholder.com/600x300',
    },
];
const feeds2 = [
    {
        id: 1,
        name: "赵志豪",
        position: "Java 开发工程师",
        company: "蚂蚁集团",
        avatar: "https://via.placeholder.com/48",
        time: "昨天 18:42",
        location: "中国",
        content:
            "欢迎来到 #蚂蚁集团，一家以创新和客户为中心的企业。我们专注于提供卓越的技术解决方案，从设计到实施，从设计到施...",
        image: "https://via.placeholder.com/600x300",
        likes: 2020,
        comments: 8909,
        shares: 7201,
        isFollowed: true,
    },
    {
        id: 2,
        name: "James Carter",
        position: "Marketing Manager",
        company: "Horizon Innovations",
        avatar: "https://via.placeholder.com/48",
        time: "昨天 18:42",
        location: "英国",
        content:
            "我们是一家致力于创新与卓越的企业，专注于为客户提供高质量的产品与服务。通过不断优化技术与流程...",
        image: "https://via.placeholder.com/600x300",
        likes: 1500,
        comments: 430,
        shares: 300,
        isFollowed: false,
    },
];

const HomePage = () => {
    return (
        <Container maxWidth="lg" sx={{ padding: '20px' }}>
            {/* 热门标签 */}
            <Box sx={{ mb: 3 }}>
                <Typography variant="h6">热门标签</Typography>
                <Box sx={{ display: 'flex', gap: 1, mt: 1 }}>
                    {hotTags.map((tag, index) => (
                        <Button key={index} variant="outlined" color="primary" size="small">
                            {tag}
                        </Button>
                    ))}
                </Box>
            </Box>

            {/* 分类 */}
            <Grid container spacing={2} sx={{ mb: 3 }}>
                {categories.map((category, index) => (
                    <Grid item xs={6} sm={3} key={index}>
                        <Box textAlign="center">
                            <Avatar sx={{ width: 60, height: 60, mx: 'auto' }}>
                                {category}
                            </Avatar>
                            <Typography>{category}</Typography>
                        </Box>
                    </Grid>
                ))}
            </Grid>

                {/* 资讯流 */}
                <Grid container spacing={2}>
                    {feeds2.map((feed) => (
                        <Grid item xs={12} key={feed.id}>
                            <Card>
                                <CardHeader
                                    avatar={<Avatar src={feed.avatar} />}
                                    title={
                                        <Box display="flex" alignItems="center" justifyContent="space-between">
                                            <Box>
                                                <Typography variant="subtitle1" fontWeight="bold">
                                                    {feed.name}
                                                </Typography>
                                                <Typography variant="caption" color="text.secondary">
                                                    {feed.position} · {feed.company}
                                                </Typography>
                                            </Box>
                                            <Button
                                                size="small"
                                                variant={feed.isFollowed ? "outlined" : "contained"}
                                                color="primary"
                                            >
                                                {feed.isFollowed ? "已关注" : "+ 关注"}
                                            </Button>
                                        </Box>
                                    }
                                    subheader={`${feed.time} · ${feed.location}`}
                                    action={
                                        <IconButton>
                                            <MoreVert />
                                        </IconButton>
                                    }
                                />
                                <CardContent>
                                    <Typography variant="body2" color="text.secondary" paragraph>
                                        {feed.content}{" "}
                                        <Button size="small" color="primary">
                                            查看更多
                                        </Button>
                                    </Typography>
                                    {feed.image && (
                                        <img
                                            src={feed.image}
                                            alt="feed"
                                            style={{ width: "100%", borderRadius: "8px" }}
                                        />
                                    )}
                                </CardContent>
                                <CardActions>
                                    <IconButton>
                                        <ThumbUp color="primary" />
                                        <Typography variant="caption" sx={{ marginLeft: 0.5 }}>
                                            {feed.likes}
                                        </Typography>
                                    </IconButton>
                                    <IconButton>
                                        <Comment />
                                        <Typography variant="caption" sx={{ marginLeft: 0.5 }}>
                                            {feed.comments}
                                        </Typography>
                                    </IconButton>
                                    <IconButton>
                                        <Share />
                                        <Typography variant="caption" sx={{ marginLeft: 0.5 }}>
                                            {feed.shares}
                                        </Typography>
                                    </IconButton>
                                </CardActions>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            {/* 资讯列表 */}
            <Box>
                {feeds.map((feed, index) => (
                    <Card sx={{ mb: 2 }} key={index}>
                        <CardContent>
                            <Box display="flex" alignItems="center" mb={2}>
                                <Avatar sx={{ mr: 2 }}>{feed.username[0]}</Avatar>
                                <Typography variant="h6">{feed.username}</Typography>
                            </Box>
                            <Typography variant="body1" sx={{ mb: 2 }}>
                                {feed.content}
                            </Typography>
                            <CardMedia
                                component="img"
                                image={feed.image}
                                alt={feed.username}
                                sx={{ borderRadius: '5px' }}
                            />
                            <Box display="flex" justifyContent="space-between" mt={2}>
                                <Button variant="text" color="primary">
                                    喜欢
                                </Button>
                                <Button variant="text" color="primary">
                                    收藏
                                </Button>
                                <Button variant="text" color="primary">
                                    分享
                                </Button>
                            </Box>
                        </CardContent>
                    </Card>
                ))}


            </Box>
        </Container>
    );
};

export default HomePage;