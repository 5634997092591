import React, { useState } from 'react';
import { Box, Container, Grid, TextField, Button, Typography, Card, CardContent } from '@mui/material';

export default function CompanyCertificationPage() {
    const [certificationData, setCertificationData] = useState({
        companyName: '',
        businessLicense: '',
        taxNumber: '',
        additionalDocuments: '',
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCertificationData({ ...certificationData, [name]: value });
    };

    const handleSubmit = () => {
        console.log('提交认证信息：', certificationData);
        alert('企业认证申请已提交！我们将在3个工作日内审核。');
    };

    return (
        <Box>
            <Container maxWidth="md" sx={{ marginTop: 4, marginBottom: 4 }}>
                <Card>
                    <CardContent>
                        <Typography variant="h5" sx={{ marginBottom: 2 }}>
                            企业认证
                        </Typography>
                        <Typography variant="body1" sx={{ marginBottom: 3 }}>
                            请填写以下信息以提交企业认证申请：
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    label="企业名称"
                                    name="companyName"
                                    fullWidth
                                    value={certificationData.companyName}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="营业执照编号"
                                    name="businessLicense"
                                    fullWidth
                                    value={certificationData.businessLicense}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="税号"
                                    name="taxNumber"
                                    fullWidth
                                    value={certificationData.taxNumber}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="附加文件说明"
                                    name="additionalDocuments"
                                    fullWidth
                                    multiline
                                    rows={3}
                                    value={certificationData.additionalDocuments}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                        </Grid>
                        <Box sx={{ marginTop: 3, textAlign: 'right' }}>
                            <Button variant="contained" color="primary" onClick={handleSubmit}>
                                提交认证
                            </Button>
                        </Box>
                    </CardContent>
                </Card>
            </Container>
        </Box>
    );
}