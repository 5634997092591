import React, { useState } from 'react';
import {
  Container,
  Grid,
  Typography,
  TextField,
  MenuItem,
  Button,
  Card,
  CardContent,
  Box,
  Select,
  FormControl,
  InputLabel,
  Snackbar,
  Alert,
} from '@mui/material';

// 支持的国家和地区
const countries = [
  { code: 'US', name: '美国' },
  { code: 'CA', name: '加拿大' },
  { code: 'GB', name: '英国' },
  { code: 'DE', name: '德国' },
  { code: 'FR', name: '法国' },
  { code: 'CN', name: '中国' },
  { code: 'JP', name: '日本' },
  { code: 'KR', name: '韩国' },
  { code: 'SG', name: '新加坡' },
  { code: 'IN', name: '印度' },
  { code: 'AU', name: '澳大利亚' },
  { code: 'NZ', name: '新西兰' },
  { code: 'AE', name: '阿联酋' },
  { code: 'SA', name: '沙特阿拉伯' },
  { code: 'BR', name: '巴西' },
  { code: 'AR', name: '阿根廷' },
];

export default function EnterpriseCertificationPage() {
  const [country, setCountry] = useState('');
  const [formData, setFormData] = useState({});
  const [fileUploads, setFileUploads] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleFileUpload = (e) => {
    setFileUploads([...fileUploads, ...e.target.files]);
  };

  const handleSubmit = () => {
    console.log('提交认证信息:', formData, fileUploads);
    setSnackbarOpen(true);
  };

  return (
    <Container maxWidth="lg" sx={{ padding: '20px' }}>
      <Typography variant="h4" gutterBottom>
        企业认证
      </Typography>

      <Card sx={{ marginBottom: 4 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            基础信息
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>国家/地区</InputLabel>
                <Select
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                >
                  {countries.map((c) => (
                    <MenuItem key={c.code} value={c.code}>
                      {c.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="企业名称"
                name="companyName"
                variant="outlined"
                onChange={handleInputChange}
                inputProps={{ maxLength: 100 }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="工商注册号/税号"
                name="registrationNumber"
                variant="outlined"
                onChange={handleInputChange}
                inputProps={{ maxLength: 50 }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="联系人姓名"
                name="contactName"
                variant="outlined"
                onChange={handleInputChange}
                inputProps={{ maxLength: 50 }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="联系人邮箱"
                name="contactEmail"
                variant="outlined"
                onChange={handleInputChange}
                inputProps={{ maxLength: 100 }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="联系人电话号码"
                name="contactPhone"
                variant="outlined"
                onChange={handleInputChange}
                inputProps={{ maxLength: 20 }}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {/* 文件上传 */}
      <Card sx={{ marginBottom: 4 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            上传认证文件
          </Typography>
          <Button variant="outlined" component="label">
            上传文件
            <input
              type="file"
              hidden
              multiple
              accept=".pdf,.jpg,.png"
              onChange={handleFileUpload}
            />
          </Button>
          <Box sx={{ marginTop: 2 }}>
            {fileUploads.map((file, index) => (
              <Typography key={index}>{file.name}</Typography>
            ))}
          </Box>
        </CardContent>
      </Card>

      <Box textAlign="right">
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          提交认证
        </Button>
      </Box>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity="success"
          sx={{ width: '100%' }}
        >
          企业认证信息提交成功！
        </Alert>
      </Snackbar>
    </Container>
  );
}