import React, { useState } from 'react';
import {
    Container,
    Typography,
    TextField,
    MenuItem,
    Button,
    Card,
    CardContent,
    Grid,
    FormControl,
    InputLabel,
    Select,
    Snackbar,
    Alert,
    Avatar,
} from '@mui/material';

const countries = [
    { code: 'US', name: '美国' },
    { code: 'CA', name: '加拿大' },
    { code: 'GB', name: '英国' },
    { code: 'DE', name: '德国' },
    { code: 'FR', name: '法国' },
    { code: 'CN', name: '中国' },
    { code: 'JP', name: '日本' },
    { code: 'KR', name: '韩国' },
    { code: 'SG', name: '新加坡' },
    { code: 'IN', name: '印度' },
    { code: 'AU', name: '澳大利亚' },
    { code: 'NZ', name: '新西兰' },
];

const industries = [
    'IT/互联网',
    '制造业',
    '金融/银行',
    '教育培训',
    '医疗卫生',
    '零售/服务业',
    '法律/咨询',
    '创意/设计',
    '物流/运输',
    '能源/环境',
];

export default function EditCompanyInfoPage() {
    const [formData, setFormData] = useState({
        companyName: '',
        companyRegion: '',
        companyIndustry: '',
        description: '',
        logo: null,
    });
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleLogoChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setFormData({ ...formData, logo: reader.result });
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSubmit = () => {
        console.log('企业信息提交:', formData);
        setSnackbarOpen(true);
    };

    return (
        <Container maxWidth="md" sx={{ padding: '20px', marginTop: 4 }}>
            <Typography variant="h4" gutterBottom>
                编辑企业信息
            </Typography>
            <Card>
                <CardContent>
                    <Grid container spacing={3}>
                        {/* 上传Logo */}
                        <Grid item xs={12} sx={{ textAlign: 'center' }}>
                            <Avatar
                                src={formData.logo}
                                alt="公司Logo"
                                sx={{ width: 120, height: 120, margin: '0 auto' }}
                            />
                            <Button
                                variant="outlined"
                                component="label"
                                sx={{ marginTop: 2 }}
                            >
                                上传Logo
                                <input
                                    type="file"
                                    accept="image/*"
                                    hidden
                                    onChange={handleLogoChange}
                                />
                            </Button>
                        </Grid>

                        {/* 公司名称 */}
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="公司名称"
                                name="companyName"
                                variant="outlined"
                                value={formData.companyName}
                                onChange={handleInputChange}
                                inputProps={{ maxLength: 100 }}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="公司网站"
                                name="companyName"
                                variant="outlined"
                                value={formData.companyName}
                                onChange={handleInputChange}
                                inputProps={{ maxLength: 100 }}
                                required
                            />
                        </Grid>

                        {/* 公司地区 */}
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel>公司地区</InputLabel>
                                <Select
                                    value={formData.companyRegion}
                                    name="companyRegion"
                                    onChange={handleInputChange}
                                    required
                                >
                                    {countries.map((country) => (
                                        <MenuItem key={country.code} value={country.name}>
                                            {country.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        {/* 公司行业 */}
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel>公司行业</InputLabel>
                                <Select
                                    value={formData.companyIndustry}
                                    name="companyIndustry"
                                    onChange={handleInputChange}
                                    required
                                >
                                    {industries.map((industry, index) => (
                                        <MenuItem key={index} value={industry}>
                                            {industry}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        {/* 简介 */}
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="简介"
                                name="description"
                                variant="outlined"
                                multiline
                                rows={4}
                                value={formData.description}
                                onChange={handleInputChange}
                                inputProps={{ maxLength: 500 }}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            {/* 提交按钮 */}
            <Grid container justifyContent="flex-end" sx={{ marginTop: 2 }}>
                <Button variant="contained" color="primary" onClick={handleSubmit}>
                    保存信息
                </Button>
            </Grid>

            {/* 提交成功提示 */}
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={() => setSnackbarOpen(false)}
            >
                <Alert
                    onClose={() => setSnackbarOpen(false)}
                    severity="success"
                    sx={{ width: '100%' }}
                >
                    企业信息保存成功！
                </Alert>
            </Snackbar>
        </Container>
    );
}