import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import Jobs from "./pages/Jobs";
import Profile from "./pages/Profile";
import Connections from "./pages/Connections";
import Feed from "./pages/Feed";
import Messages from "./pages/message/Messages.js";
import HeaderNavBar from "./components/HeaderNavBar";
import AiHelper from './components/AiHelper';

import RecommendedJobsPage from './pages/RecommendedJobsPage';
import RecommendedCandidatesPage from './pages/RecommendedCandidatesPage';

import HomePage from "./pages/HomePage";
import JobsPage from "./pages/JobsPage.js";
import PostPage from "./pages/PostPage";
import MessagesPage from "./pages/message/MessagesPage.js";
import ProfilePage from "./pages/ProfilePage";
import BottomNavBar from "./components/BottomNavBar";
import EditCompanyInfoPage from "./pages/EditCompanyInfoPage";
import CompanyCertificationPage from "./pages/CompanyCertificationPage";
import EnterpriseCertificationPage from './pages/EnterpriseCertificationPage'; // 路径需根据项目结构调整
import InterestAndProfilePage from './pages/InterestAndProfilePage';
import LoginPage from './pages/LoginPage';


function App() {
  return (
    <Router>
      {/* <Header /> */}
      <HeaderNavBar />
      <Routes>

        <Route path="/" element={<HomePage />} />
        <Route path="/jobs" element={<JobsPage />} />
        <Route path="/post" element={<PostPage />} />
        <Route path="/messages" element={<MessagesPage />} />
        <Route path="/profile" element={<ProfilePage />} />
        <Route path="/edit-company-info" element={<EditCompanyInfoPage />} />
        <Route path="/company-certification" element={CompanyCertificationPage} />
        <Route path="/enterprise-certification" element={<EnterpriseCertificationPage />} />
        <Route path="/interest-and-profile" element={<InterestAndProfilePage userType="personal" />} />
        <Route path="/login" element={<LoginPage />} />
        {/* <Route path="/" element={<Home />} />
        <Route path="/jobs" element={<Jobs />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/connections" element={<Connections />} />
        <Route path="/feed" element={<Feed />} />
        <Route path="/messages" element={<Messages />} />
        <Route path="/recommended-jobs" element={<RecommendedJobsPage />} />
        <Route path="/recommended-candidates" element={<RecommendedCandidatesPage />} /> */}
      </Routes>
      <AiHelper />

      <BottomNavBar />
      {/* <Footer /> */}
    </Router>
  );
}

export default App;