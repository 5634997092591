import React, { useState } from "react";
import { BottomNavigation, BottomNavigationAction, Paper } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import WorkIcon from "@mui/icons-material/Work";
import AddBoxIcon from "@mui/icons-material/AddBox";
import MessageIcon from "@mui/icons-material/Message";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Home, Work, AddBox, Chat, Person } from '@mui/icons-material';
import { useNavigate } from "react-router-dom";

const BottomNavBar = () => {
    const [value, setValue] = useState(0);
    const navigate = useNavigate();

    const handleNavigation = (newValue) => {
        setValue(newValue);

        // 根据选中的菜单项跳转
        switch (newValue) {
            case 0:
                navigate("/");
                break;
            case 1:
                navigate("/jobs");
                break;
            case 2:
                navigate("/post");
                break;
            case 3:
                navigate("/messages");
                break;
            case 4:
                navigate("/profile");
                break;
            default:
                break;
        }
    };

    return (
        <Paper
            sx={{
                position: "fixed",
                bottom: 0,
                left: 0,
                right: 0,
                zIndex: 10,
            }}
            elevation={3}
        >
            <BottomNavigation
                value={value}
                onChange={(event, newValue) => handleNavigation(newValue)}
                showLabels
            >
                <BottomNavigationAction label="首页" icon={<HomeIcon />} />
                <BottomNavigationAction label="求职" icon={<WorkIcon />} />
                <BottomNavigationAction label="发布" icon={<AddBoxIcon />} />
                <BottomNavigationAction label="消息" icon={<Chat />} onClick={() => navigate('/messages')} />
                <BottomNavigationAction label="我的" icon={<AccountCircleIcon />} />
            </BottomNavigation>
        </Paper>
    );
};

export default BottomNavBar;