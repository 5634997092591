import React, { useState } from 'react';
import {
  Box,
  Fab,
  Modal,
  Typography,
  TextField,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import ChatIcon from '@mui/icons-material/Chat';
import CloseIcon from '@mui/icons-material/Close';

const AiChatHelper = () => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState(''); // 当前输入的消息
  const [chatHistory, setChatHistory] = useState([]); // 聊天记录

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setMessage('');
    setChatHistory([]);
  };

  const handleSendMessage = () => {
    if (message.trim() === '') return;

    // 模拟AI响应的逻辑
    const aiResponse = `AI助理: 我已处理您的请求：${message}`;
    setChatHistory((prev) => [...prev, { type: 'user', content: message }, { type: 'ai', content: aiResponse }]);
    setMessage('');
  };

  return (
    <>
      {/* 悬浮按钮 */}
      <Box
        sx={{
          position: 'fixed',
          top: 116,
          right: 16,
          zIndex: 1000,
        }}
      >
        <Fab color="primary" onClick={handleOpen}>
          <ChatIcon />
        </Fab>
      </Box>

      {/* 对话框 */}
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            height: 500,
            bgcolor: 'background.paper',
            boxShadow: 24,
            borderRadius: 2,
            p: 3,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {/* 对话框头部 */}
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h6">AI助力</Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>

          {/* 聊天历史记录 */}
          <Box
            sx={{
              flexGrow: 1,
              overflowY: 'auto',
              marginTop: 2,
              marginBottom: 2,
              padding: 1,
              backgroundColor: '#f5f5f5',
              borderRadius: 1,
            }}
          >
            <List>
              {chatHistory.map((chat, index) => (
                <ListItem key={index} sx={{ justifyContent: chat.type === 'user' ? 'flex-end' : 'flex-start' }}>
                  <ListItemText
                    primary={chat.content}
                    sx={{
                      textAlign: chat.type === 'user' ? 'right' : 'left',
                      backgroundColor: chat.type === 'user' ? '#d1e7dd' : '#f8d7da',
                      padding: 1,
                      borderRadius: 2,
                      maxWidth: '80%',
                    }}
                  />
                </ListItem>
              ))}
            </List>
          </Box>

          {/* 输入框 */}
          <TextField
            multiline
            rows={2}
            fullWidth
            variant="outlined"
            placeholder="请输入您的问题或请求"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            sx={{ mb: 2 }}
          />
          <Button variant="contained" color="primary" fullWidth onClick={handleSendMessage}>
            发送
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default AiChatHelper;