import React from 'react';
import MessageCard from './MessageCard';
import { Typography } from '@mui/material';

export default function JobChatList() {
    const jobChats = [
        {
            avatar: 'https://via.placeholder.com/40',
            title: '招聘经理 - 六喜珠宝',
            time: '今天 08:30',
            content: '我们很喜欢您的简历，能否安排一次电话面试？',
        },
        {
            avatar: 'https://via.placeholder.com/40',
            title: 'HR - 文思海辉',
            time: '昨天 17:45',
            content: '关于工作地点的问题，请与我们HR沟通。',
        },
    ];

    return (
        <>
            <Typography variant="h6" gutterBottom>
            </Typography>
            {jobChats.map((chat, index) => (
                <MessageCard
                    key={index}
                    avatar={chat.avatar}
                    title={chat.title}
                    time={chat.time}
                    content={chat.content}
                    onClick={() => console.log('跳转到求职聊天页面')}
                />
            ))}
        </>
    );
}